import React, { PureComponent, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'model/axios'
import Cookies from 'js-cookie'
import fetchData from 'api/fetch-data'

export const NotificationContext = createContext()

export class NotificationProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      unreadNotificationsCount: 0,
      isUserChannel: false,
      showKPMGNotification: false,
      isLoadingNotifications: true,
    }
    this.socket = {}
  }

  componentDidMount() {
    const { userId } = this.props

    if (userId !== '') {
      this.fetchNotifications()
      this.getUserChannelData()
      this.checkUserCreationDate()
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.props

    if (userId !== '' && userId !== prevProps.userId) {
      this.fetchNotifications()
    }
  }

  getUserChannelData = async () => {
    const { userId } = this.props

    try {
      const result = await fetchData('incoming-user-channel/get')
      if (result && result.list.length > 0) {
        if (userId) {
          if (result.list.find((finduser) => finduser.user_id === userId)) {
            this.setState({ isUserChannel: true })
          }
        }
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }

  //for KPMG we show notification to users who onboarder after 25 Nov
  checkUserCreationDate = () => {
    const { user } = this.props
    const { metadata } = user
    const KPMGOfferingDate = new Date('2024-11-25T00:00:00Z')

    if (new Date(metadata?.creationTime) > KPMGOfferingDate) {
      this.setState({ showKPMGNotification: true })
    }
  }

  fetchNotifications = async () => {
    const { checkTokenIsValid } = this.props

    if (checkTokenIsValid()) {
      try {
        const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')
        const response = await axios.get('/notification/user/list', {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        const notifications = response.data
        const unreadNotificationsCount = (notifications || []).filter(
          (notification) => !notification.isRead,
        ).length

        this.setState({
          notifications,
          unreadNotificationsCount,
          isLoadingNotifications: false,
        })
      } catch (error) {
        console.log('🚀 ~ error', error)
        this.setState({ isLoadingNotifications: false })
      }
    } else {
      this.setState({
        notifications: [],
        unreadNotificationsCount: 0,
        isLoadingNotifications: false,
      })
    }
  }

  toggleIsRead = async (notificationId) => {
    const { checkTokenIsValid } = this.props

    if (checkTokenIsValid()) {
      await fetchData('/notification/user/toggle-is-read', {
        notificationId,
      })

      const { notifications } = this.state
      const notification = notifications.find(
        (notification) => notification.notification._id === notificationId,
      )

      notification.isRead = !notification.isRead

      const unreadNotificationsCount = (notifications || []).filter(
        (notification) => !notification.isRead,
      ).length

      this.setState({ notifications, unreadNotificationsCount })
    } else {
      this.setState({ notifications: [], unreadNotificationsCount: 0 })
    }
  }

  render() {
    const { children } = this.props

    return (
      <NotificationContext.Provider
        value={{
          ...this.state,
          toggleIsRead: this.toggleIsRead,
        }}
      >
        {children}
      </NotificationContext.Provider>
    )
  }
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string,
}

NotificationProvider.defaultProps = {
  userId: '',
}

export const NotificationConsumer = NotificationContext.Consumer
