import PropTypes from 'prop-types'
import React from 'react'

import {
  Container,
  Wrap,
  Items,
  Item,
  ProgressText,
} from './TransferProgress.styles'

const TransferProgress = ({ doneIndex, isRedemption, steps }) => {
  const [progressText, setProgressText] = React.useState(
    steps[doneIndex]?.label,
  )

  //current index is the index of hovering element
  const [currentIndex, setCurrentIndex] = React.useState(doneIndex)

  const getStepText = (step) => {
    return steps[step]?.label
  }

  return (
    <Container>
      <Wrap>
        <Items>
          {steps.map((step, index) => (
            <Item
              key={step.label + index}
              isRedemption={isRedemption}
              isDone={index <= doneIndex}
              onClick={() => {
                //item.handleClick && item.handleClick(item.id)
              }}
              onMouseEnter={() => {
                setCurrentIndex(index)
                setProgressText(getStepText(index))
              }}
              onMouseLeave={() => {
                setCurrentIndex(doneIndex)
                setProgressText(getStepText(doneIndex))
              }}
            />
          ))}
        </Items>
      </Wrap>
      <ProgressText
        isDone={currentIndex <= doneIndex}
        isRedemption={isRedemption}
      >
        {progressText}
      </ProgressText>
    </Container>
  )
}

TransferProgress.propTypes = {
  steps: PropTypes.array.isRequired,
  doneIndex: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
}

TransferProgress.defaultProps = {
  doneIndex: -1,
  activeIndex: -1,
}

export default TransferProgress
