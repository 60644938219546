import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import TransferProgress from 'components/TransferProgress/TransferProgress'

import TransactionInfo from 'components/TransactionInfo/TransactionInfo'
import {
  Container,
  Label,
  Title,
  Text,
  Checkmark,
  NotificationUrl,
} from './NotificationItem.styles'
import {
  Icon,
  iconsMap,
} from '@stokr/components-library/dist/components/Icon/Icon.style'

const getLabelForType = (type) => {
  switch (type) {
    case 'TRANSFER_IN_PROGRESS':
      return 'Transfer in progress'
    case 'PORTFOLIO_UPDATES':
      return 'Portfolio updates'
    case 'STOKR_UPDATES':
      return 'STOKR Updates'
    default:
      return ''
  }
}

const NotificationItem = ({
  type,
  date,
  title,
  allSteps,
  text,
  isRead,
  handleCloseClick,
  onlyInvestmentNotifications,
  isFinalisedTx,
  transfer,
  progress,
  ctaLabel,
  ctaUrl,
  ctaNewTab,
  tooltip,
  fakeNotification,
  withCTAOnCenter,
  handleCTAClick,
  transaction,
}) => {
  const ctaRef = useRef(null)
  return (
    <Container style={fakeNotification && { opacity: '0' }}>
      {/* <Close onClick={handleCloseClick} /> */}
      {!onlyInvestmentNotifications && (
        <Checkmark
          isRead={onlyInvestmentNotifications && isFinalisedTx ? true : isRead}
          onClick={!onlyInvestmentNotifications && handleCloseClick}
          onlyInvestmentNotifications={onlyInvestmentNotifications}
        />
      )}
      {!onlyInvestmentNotifications && (
        <Label>
          {isFinalisedTx ? 'TRANSFER FINALISED' : getLabelForType(type)}
        </Label>
      )}

      <Label
        style={{ marginBottom: onlyInvestmentNotifications ? '20px' : '5px' }}
      >
        {date}
      </Label>
      {!onlyInvestmentNotifications && <Title>{title}</Title>}
      <Text>{text}</Text>
      {transfer && (
        <TransactionInfo
          name1={transfer.from.name}
          unit1={transfer.from.unit}
          value1={transfer.from.value}
          name2={transfer.to.name}
          unit2={transfer.to.unit}
          value2={transfer.to.value}
          tokenDecimals={transfer.tokenDecimals}
        />
      )}
      {progress && <TransferProgress {...progress} steps={allSteps} />}

      {ctaLabel && ctaUrl && (
        <NotificationUrl
          withCTAOnCenter={withCTAOnCenter}
          onClick={(e) => {
            if (handleCTAClick) {
              e.preventDefault()
              handleCTAClick(transaction)
            } else {
              ctaRef.current.click()
            }
          }}
        >
          <a
            href={ctaUrl}
            target={ctaNewTab ? '_blank' : '_self'}
            rel="noreferrer"
            ref={ctaRef}
          >
            <span>
              <Icon icon={iconsMap.arrowRight} />
              {ctaLabel}
            </span>
          </a>
        </NotificationUrl>
      )}
    </Container>
  )
}

NotificationItem.propTypes = {
  type: PropTypes.oneOf([
    'TRANSFER_IN_PROGRESS',
    'PORTFOLIO_UPDATES',
    'STOKR_UPDATES',
  ]).isRequired,
  date: PropTypes.string,
  title: PropTypes.string.isRequired,
  nextStep: PropTypes.string,
  text: PropTypes.string,
  handleCloseClick: PropTypes.func,
}

NotificationItem.defaultProps = {
  date: '',
  text: '',
  nextStep: '',
  progress: null,
  transfer: null,
  handleCloseClick: () => {},
  handleCTAClick: null,
}

export default NotificationItem
