import React from 'react'
import PropTypes from 'prop-types'
import { Background, Button, ComponentWrapper } from '@stokr/components-library'
import { Container, Cover, Title, Content } from './LearnMoreItem.styles'
import { SidebarSubHeading } from './LearnMore.styles'

const LearnMoreItem = ({
  title,
  content,
  link,
  coverSrc,
  postType,
  videoSrc,
  category,
}) => {
  return (
    <Container data-testid="learnMoreItem">
      <Cover>
        <Background src={coverSrc} investmentOpportunities />
      </Cover>
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <SidebarSubHeading>{category}</SidebarSubHeading>
        <Title>{title}</Title>
      </ComponentWrapper>
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <Content>{content}</Content>
      </ComponentWrapper>
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <Button
          onClick={() => {
            window.location.replace(link)
          }}
          id="learn-more-project-button"
        >
          {/* {postType === 'video' ? 'Learn More' : 'Learn More'} */}
          {title === 'ARTID' ||
          title === 'INFINITE-FLEET' ||
          title === 'BLOCKSTREAM MINING' ||
          title === 'MAZZANTI AUTOMOBILI'
            ? 'FULL DETAILS'
            : `I'M INTERESTED`}
        </Button>
      </ComponentWrapper>
    </Container>
  )
}

LearnMoreItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
}

export default LearnMoreItem
